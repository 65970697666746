export const environment = {
  production: true,
  botName: 'HobbestBot',
  apiOrigin: 'devapi.hobbyts.com',
  origin: 'dev.hobbyts.com',
  cacheVersion: 'v1',
  googleMapsApiKey: 'AIzaSyDmNMoIrK831CPPzXPvwHLdCUavluk3W68',
  countryByIpApiKey: 'bcz26h8p15uma2nk',
  firebaseConfig: {
    apiKey: "AIzaSyAomLsmpK6kLdlDeIZtC9Fqpsuiv1bnLJo",
    authDomain: "hobbit-777.firebaseapp.com",
    databaseURL: "https://hobbit-777.firebaseio.com",
    projectId: "hobbit-777",
    storageBucket: "hobbit-777.appspot.com",
    messagingSenderId: "431825739080",
    appId: "1:431825739080:web:078ecdf55138d9dd7fa77d"
  }
};
